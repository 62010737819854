/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api4af2df69",
            "endpoint": "https://jb58qoaxz9.execute-api.eu-west-1.amazonaws.com/discovery",
            "region": "eu-west-1"
        },
        {
            "name": "apif79b844d",
            "endpoint": "https://7u7zvvuraj.execute-api.eu-west-1.amazonaws.com/discovery",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://urn2bjhktvgrhduu3tg5hj3s5i.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-dcoodxvrdjacbhzgbg4iu5s7dy",
    "aws_cognito_identity_pool_id": "eu-west-1:a989c03e-c788-42d6-a300-fbd7ac8d5866",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_fIgmW8VwN",
    "aws_user_pools_web_client_id": "6aome16nf4lt0p30o96n3usogl",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "ltcs957227e4e170496f986198549508735a200459-discovery",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
