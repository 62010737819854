/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      employeeFND
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      connectedTo
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      postGridID
      sendSMS
      sendEmail
      testTwo
      setting
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      employeeFND
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      connectedTo
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      postGridID
      sendSMS
      sendEmail
      testTwo
      setting
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      employeeFND
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      connectedTo
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      postGridID
      sendSMS
      sendEmail
      testTwo
      setting
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      clientID
      name
      contact_name
      phone_number
      email
      contact_email
      contact_fax
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      associatedClients
      defaultProvider
      practiceID
      fax
      isTesting
      isAutoSubmitClaim
      isActive
      isArchive
      discount
      commission
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      isDefault
      note
      setting
      labLocalID
      excludedCPT
      npi
      licProvNo
      clientGroup
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      clientID
      name
      contact_name
      phone_number
      email
      contact_email
      contact_fax
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      associatedClients
      defaultProvider
      practiceID
      fax
      isTesting
      isAutoSubmitClaim
      isActive
      isArchive
      discount
      commission
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      isDefault
      note
      setting
      labLocalID
      excludedCPT
      npi
      licProvNo
      clientGroup
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      clientID
      name
      contact_name
      phone_number
      email
      contact_email
      contact_fax
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      associatedClients
      defaultProvider
      practiceID
      fax
      isTesting
      isAutoSubmitClaim
      isActive
      isArchive
      discount
      commission
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      isDefault
      note
      setting
      labLocalID
      excludedCPT
      npi
      licProvNo
      clientGroup
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      mdSetting
      npi
      licProvNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      mdSetting
      npi
      licProvNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      mdSetting
      npi
      licProvNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployeeOrder = /* GraphQL */ `
  mutation CreateEmployeeOrder(
    $input: CreateEmployeeOrderInput!
    $condition: ModelEmployeeOrderConditionInput
  ) {
    createEmployeeOrder(input: $input, condition: $condition) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedSource
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployeeOrder = /* GraphQL */ `
  mutation UpdateEmployeeOrder(
    $input: UpdateEmployeeOrderInput!
    $condition: ModelEmployeeOrderConditionInput
  ) {
    updateEmployeeOrder(input: $input, condition: $condition) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedSource
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployeeOrder = /* GraphQL */ `
  mutation DeleteEmployeeOrder(
    $input: DeleteEmployeeOrderInput!
    $condition: ModelEmployeeOrderConditionInput
  ) {
    deleteEmployeeOrder(input: $input, condition: $condition) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedSource
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAppSetting = /* GraphQL */ `
  mutation CreateAppSetting(
    $input: CreateAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    createAppSetting(input: $input, condition: $condition) {
      id
      code
      employeeCounter
      orderId
      orderCounter
      invoiceCounter
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      otherNPI
      renderingProvider
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAppSetting = /* GraphQL */ `
  mutation UpdateAppSetting(
    $input: UpdateAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    updateAppSetting(input: $input, condition: $condition) {
      id
      code
      employeeCounter
      orderId
      orderCounter
      invoiceCounter
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      otherNPI
      renderingProvider
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAppSetting = /* GraphQL */ `
  mutation DeleteAppSetting(
    $input: DeleteAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    deleteAppSetting(input: $input, condition: $condition) {
      id
      code
      employeeCounter
      orderId
      orderCounter
      invoiceCounter
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      otherNPI
      renderingProvider
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInsuranceCompanies = /* GraphQL */ `
  mutation CreateInsuranceCompanies(
    $input: CreateInsuranceCompaniesInput!
    $condition: ModelInsuranceCompaniesConditionInput
  ) {
    createInsuranceCompanies(input: $input, condition: $condition) {
      id
      clientID
      payerId
      code
      payerType
      name
      shortName
      idFormat
      address
      city
      state
      phone
      fax
      eClaim
      eFax
      manualTerm
      manualAmount
      manualMail
      eEligibility
      EDI
      ERA
      eraMail
      claimAddress
      claimCity
      claimState
      claimPhone
      claimFax
      updatedBy
      updatedByName
      createdByName
      claims
      claimAmount
      openAmount
      paid
      totalAppeal
      avgTime
      lastClaim
      days30
      days60
      days90
      older
      npi
      cliaNumber
      network
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInsuranceCompanies = /* GraphQL */ `
  mutation UpdateInsuranceCompanies(
    $input: UpdateInsuranceCompaniesInput!
    $condition: ModelInsuranceCompaniesConditionInput
  ) {
    updateInsuranceCompanies(input: $input, condition: $condition) {
      id
      clientID
      payerId
      code
      payerType
      name
      shortName
      idFormat
      address
      city
      state
      phone
      fax
      eClaim
      eFax
      manualTerm
      manualAmount
      manualMail
      eEligibility
      EDI
      ERA
      eraMail
      claimAddress
      claimCity
      claimState
      claimPhone
      claimFax
      updatedBy
      updatedByName
      createdByName
      claims
      claimAmount
      openAmount
      paid
      totalAppeal
      avgTime
      lastClaim
      days30
      days60
      days90
      older
      npi
      cliaNumber
      network
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInsuranceCompanies = /* GraphQL */ `
  mutation DeleteInsuranceCompanies(
    $input: DeleteInsuranceCompaniesInput!
    $condition: ModelInsuranceCompaniesConditionInput
  ) {
    deleteInsuranceCompanies(input: $input, condition: $condition) {
      id
      clientID
      payerId
      code
      payerType
      name
      shortName
      idFormat
      address
      city
      state
      phone
      fax
      eClaim
      eFax
      manualTerm
      manualAmount
      manualMail
      eEligibility
      EDI
      ERA
      eraMail
      claimAddress
      claimCity
      claimState
      claimPhone
      claimFax
      updatedBy
      updatedByName
      createdByName
      claims
      claimAmount
      openAmount
      paid
      totalAppeal
      avgTime
      lastClaim
      days30
      days60
      days90
      older
      npi
      cliaNumber
      network
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSubAgent = /* GraphQL */ `
  mutation CreateSubAgent(
    $input: CreateSubAgentInput!
    $condition: ModelSubAgentConditionInput
  ) {
    createSubAgent(input: $input, condition: $condition) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSubAgent = /* GraphQL */ `
  mutation UpdateSubAgent(
    $input: UpdateSubAgentInput!
    $condition: ModelSubAgentConditionInput
  ) {
    updateSubAgent(input: $input, condition: $condition) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSubAgent = /* GraphQL */ `
  mutation DeleteSubAgent(
    $input: DeleteSubAgentInput!
    $condition: ModelSubAgentConditionInput
  ) {
    deleteSubAgent(input: $input, condition: $condition) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEligibiliyFiles = /* GraphQL */ `
  mutation CreateEligibiliyFiles(
    $input: CreateEligibiliyFilesInput!
    $condition: ModelEligibiliyFilesConditionInput
  ) {
    createEligibiliyFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEligibiliyFiles = /* GraphQL */ `
  mutation UpdateEligibiliyFiles(
    $input: UpdateEligibiliyFilesInput!
    $condition: ModelEligibiliyFilesConditionInput
  ) {
    updateEligibiliyFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEligibiliyFiles = /* GraphQL */ `
  mutation DeleteEligibiliyFiles(
    $input: DeleteEligibiliyFilesInput!
    $condition: ModelEligibiliyFilesConditionInput
  ) {
    deleteEligibiliyFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClaimFiles = /* GraphQL */ `
  mutation CreateClaimFiles(
    $input: CreateClaimFilesInput!
    $condition: ModelClaimFilesConditionInput
  ) {
    createClaimFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClaimFiles = /* GraphQL */ `
  mutation UpdateClaimFiles(
    $input: UpdateClaimFilesInput!
    $condition: ModelClaimFilesConditionInput
  ) {
    updateClaimFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClaimFiles = /* GraphQL */ `
  mutation DeleteClaimFiles(
    $input: DeleteClaimFilesInput!
    $condition: ModelClaimFilesConditionInput
  ) {
    deleteClaimFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployeeClaims = /* GraphQL */ `
  mutation CreateEmployeeClaims(
    $input: CreateEmployeeClaimsInput!
    $condition: ModelEmployeeClaimsConditionInput
  ) {
    createEmployeeClaims(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedSource
      testID
      openBalance
      openStatus
      billTo
      accNo
      isHospice
      snf
      isInsHousing
      homeBound
      toData
      write_off
      appealID
      createdBy
      createdByName
      eligibility
      additional
      isInjury
      isAccident
      injuryDate
      stateOfInjury
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployeeClaims = /* GraphQL */ `
  mutation UpdateEmployeeClaims(
    $input: UpdateEmployeeClaimsInput!
    $condition: ModelEmployeeClaimsConditionInput
  ) {
    updateEmployeeClaims(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedSource
      testID
      openBalance
      openStatus
      billTo
      accNo
      isHospice
      snf
      isInsHousing
      homeBound
      toData
      write_off
      appealID
      createdBy
      createdByName
      eligibility
      additional
      isInjury
      isAccident
      injuryDate
      stateOfInjury
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployeeClaims = /* GraphQL */ `
  mutation DeleteEmployeeClaims(
    $input: DeleteEmployeeClaimsInput!
    $condition: ModelEmployeeClaimsConditionInput
  ) {
    deleteEmployeeClaims(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedSource
      testID
      openBalance
      openStatus
      billTo
      accNo
      isHospice
      snf
      isInsHousing
      homeBound
      toData
      write_off
      appealID
      createdBy
      createdByName
      eligibility
      additional
      isInjury
      isAccident
      injuryDate
      stateOfInjury
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProviderInfo = /* GraphQL */ `
  mutation CreateProviderInfo(
    $input: CreateProviderInfoInput!
    $condition: ModelProviderInfoConditionInput
  ) {
    createProviderInfo(input: $input, condition: $condition) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      npiType
      taxid
      speciality
      email
      phoneNumber
      fax
      street
      street2
      city
      state
      zip
      isDefault
      orders
      claimAmount
      openAmount
      paid
      lastOrder
      note
      isActive
      associatedClient
      setting
      subID
      createdBy
      createdByName
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProviderInfo = /* GraphQL */ `
  mutation UpdateProviderInfo(
    $input: UpdateProviderInfoInput!
    $condition: ModelProviderInfoConditionInput
  ) {
    updateProviderInfo(input: $input, condition: $condition) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      npiType
      taxid
      speciality
      email
      phoneNumber
      fax
      street
      street2
      city
      state
      zip
      isDefault
      orders
      claimAmount
      openAmount
      paid
      lastOrder
      note
      isActive
      associatedClient
      setting
      subID
      createdBy
      createdByName
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProviderInfo = /* GraphQL */ `
  mutation DeleteProviderInfo(
    $input: DeleteProviderInfoInput!
    $condition: ModelProviderInfoConditionInput
  ) {
    deleteProviderInfo(input: $input, condition: $condition) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      npiType
      taxid
      speciality
      email
      phoneNumber
      fax
      street
      street2
      city
      state
      zip
      isDefault
      orders
      claimAmount
      openAmount
      paid
      lastOrder
      note
      isActive
      associatedClient
      setting
      subID
      createdBy
      createdByName
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCompanyStats = /* GraphQL */ `
  mutation CreateCompanyStats(
    $input: CreateCompanyStatsInput!
    $condition: ModelCompanyStatsConditionInput
  ) {
    createCompanyStats(input: $input, condition: $condition) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCompanyStats = /* GraphQL */ `
  mutation UpdateCompanyStats(
    $input: UpdateCompanyStatsInput!
    $condition: ModelCompanyStatsConditionInput
  ) {
    updateCompanyStats(input: $input, condition: $condition) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCompanyStats = /* GraphQL */ `
  mutation DeleteCompanyStats(
    $input: DeleteCompanyStatsInput!
    $condition: ModelCompanyStatsConditionInput
  ) {
    deleteCompanyStats(input: $input, condition: $condition) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInvoices = /* GraphQL */ `
  mutation CreateInvoices(
    $input: CreateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    createInvoices(input: $input, condition: $condition) {
      id
      invoiceNo
      invoiceDate
      invoiceSendDate
      invoiceReceived
      details
      firstName
      middleName
      lastName
      street
      street2
      city
      state
      country
      zip
      phoneNumber
      email
      sendToType
      sendTo
      employeeID
      claimID
      clientID
      companyID
      subAgentID
      dueDate
      invoiceAmount
      minAmount
      discount
      status
      postGridID
      postGridStatus
      postGridMessage
      transcation_id
      url
      note
      message
      paymentMethod
      paidAmount
      paidDate
      createdBy
      createdByName
      updatedBy
      updatedByName
      isActive
      sendBy
      sendByName
      cancelBy
      cancelByName
      mailingClass
      toData
      fromData
      discountType
      totalPayable
      viewType
      sentData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInvoices = /* GraphQL */ `
  mutation UpdateInvoices(
    $input: UpdateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    updateInvoices(input: $input, condition: $condition) {
      id
      invoiceNo
      invoiceDate
      invoiceSendDate
      invoiceReceived
      details
      firstName
      middleName
      lastName
      street
      street2
      city
      state
      country
      zip
      phoneNumber
      email
      sendToType
      sendTo
      employeeID
      claimID
      clientID
      companyID
      subAgentID
      dueDate
      invoiceAmount
      minAmount
      discount
      status
      postGridID
      postGridStatus
      postGridMessage
      transcation_id
      url
      note
      message
      paymentMethod
      paidAmount
      paidDate
      createdBy
      createdByName
      updatedBy
      updatedByName
      isActive
      sendBy
      sendByName
      cancelBy
      cancelByName
      mailingClass
      toData
      fromData
      discountType
      totalPayable
      viewType
      sentData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInvoices = /* GraphQL */ `
  mutation DeleteInvoices(
    $input: DeleteInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    deleteInvoices(input: $input, condition: $condition) {
      id
      invoiceNo
      invoiceDate
      invoiceSendDate
      invoiceReceived
      details
      firstName
      middleName
      lastName
      street
      street2
      city
      state
      country
      zip
      phoneNumber
      email
      sendToType
      sendTo
      employeeID
      claimID
      clientID
      companyID
      subAgentID
      dueDate
      invoiceAmount
      minAmount
      discount
      status
      postGridID
      postGridStatus
      postGridMessage
      transcation_id
      url
      note
      message
      paymentMethod
      paidAmount
      paidDate
      createdBy
      createdByName
      updatedBy
      updatedByName
      isActive
      sendBy
      sendByName
      cancelBy
      cancelByName
      mailingClass
      toData
      fromData
      discountType
      totalPayable
      viewType
      sentData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClientTestSetting = /* GraphQL */ `
  mutation CreateClientTestSetting(
    $input: CreateClientTestSettingInput!
    $condition: ModelClientTestSettingConditionInput
  ) {
    createClientTestSetting(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      test
      charge
      defaultCPT
      defaultICT
      defaultProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClientTestSetting = /* GraphQL */ `
  mutation UpdateClientTestSetting(
    $input: UpdateClientTestSettingInput!
    $condition: ModelClientTestSettingConditionInput
  ) {
    updateClientTestSetting(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      test
      charge
      defaultCPT
      defaultICT
      defaultProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClientTestSetting = /* GraphQL */ `
  mutation DeleteClientTestSetting(
    $input: DeleteClientTestSettingInput!
    $condition: ModelClientTestSettingConditionInput
  ) {
    deleteClientTestSetting(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      test
      charge
      defaultCPT
      defaultICT
      defaultProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLedger = /* GraphQL */ `
  mutation CreateLedger(
    $input: CreateLedgerInput!
    $condition: ModelLedgerConditionInput
  ) {
    createLedger(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      employeeID
      cr
      dr
      payment_method
      payment_type
      payment_no
      date_of_service
      date_of_payment
      transcation_id
      invoice_no
      transcation_status
      description
      isActive
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLedger = /* GraphQL */ `
  mutation UpdateLedger(
    $input: UpdateLedgerInput!
    $condition: ModelLedgerConditionInput
  ) {
    updateLedger(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      employeeID
      cr
      dr
      payment_method
      payment_type
      payment_no
      date_of_service
      date_of_payment
      transcation_id
      invoice_no
      transcation_status
      description
      isActive
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLedger = /* GraphQL */ `
  mutation DeleteLedger(
    $input: DeleteLedgerInput!
    $condition: ModelLedgerConditionInput
  ) {
    deleteLedger(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      employeeID
      cr
      dr
      payment_method
      payment_type
      payment_no
      date_of_service
      date_of_payment
      transcation_id
      invoice_no
      transcation_status
      description
      isActive
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate(
    $input: CreateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    createMessageTemplate(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    updateMessageTemplate(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMessageTemplate = /* GraphQL */ `
  mutation DeleteMessageTemplate(
    $input: DeleteMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    deleteMessageTemplate(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUploadRequestMain = /* GraphQL */ `
  mutation CreateUploadRequestMain(
    $input: CreateUploadRequestMainInput!
    $condition: ModelUploadRequestMainConditionInput
  ) {
    createUploadRequestMain(input: $input, condition: $condition) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUploadRequestMain = /* GraphQL */ `
  mutation UpdateUploadRequestMain(
    $input: UpdateUploadRequestMainInput!
    $condition: ModelUploadRequestMainConditionInput
  ) {
    updateUploadRequestMain(input: $input, condition: $condition) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUploadRequestMain = /* GraphQL */ `
  mutation DeleteUploadRequestMain(
    $input: DeleteUploadRequestMainInput!
    $condition: ModelUploadRequestMainConditionInput
  ) {
    deleteUploadRequestMain(input: $input, condition: $condition) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUploadRequest = /* GraphQL */ `
  mutation CreateUploadRequest(
    $input: CreateUploadRequestInput!
    $condition: ModelUploadRequestConditionInput
  ) {
    createUploadRequest(input: $input, condition: $condition) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUploadRequest = /* GraphQL */ `
  mutation UpdateUploadRequest(
    $input: UpdateUploadRequestInput!
    $condition: ModelUploadRequestConditionInput
  ) {
    updateUploadRequest(input: $input, condition: $condition) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUploadRequest = /* GraphQL */ `
  mutation DeleteUploadRequest(
    $input: DeleteUploadRequestInput!
    $condition: ModelUploadRequestConditionInput
  ) {
    deleteUploadRequest(input: $input, condition: $condition) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
